import React, { useState, useContext, useRef } from "react";
import {
  CloseCircleOutlined,
  CopyOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import pluginsGroup from "@/plugins";
import {
  DrawPageStateContext,
  InsertContext,
  BaseConfigContext,
  TipsContext,
  ModulesContext,
} from "@/context";
import { TextArea } from "@/components";
import CX from "classnames";
import "./index.less";

// 导入导出弹窗
export default function ExportAndImportAlert() {
  const [isExportTabActive, setIsExportTabActive] = useState(true);
  const { insertList, insert, isInputInsert } = useContext(InsertContext);
  const { currentModule, setShowExportAndImport, setCurrentModule } =
    useContext(DrawPageStateContext);
  const { maxSize } = useContext(BaseConfigContext);
  const { useMessage } = useContext(TipsContext);
  const { findModuleByName } = useContext(ModulesContext);
  const copyRef = useRef(null);
  const importRef = useRef("");
  const createReleaseData = () => {
    let result = null;
    if (currentModule !== null && insertList.length > 0) {
      result = {
        baseConfig: maxSize,
        code: currentModule.code,
        name: currentModule.name,
        module_id: currentModule.module_id,
        plugins: insertList.map((item) => {
          // 减少保存的字段长度
          return {
            name: item.name,
            type: item.type,
            props: item.props,
            rectOptions: item.rectOptions,
          };
        }),
      };
    }
    return JSON.stringify(result);
  };

  const mergeNewPluginsToOld = (newPlugin) => {
    let result = {};
    Object.values(pluginsGroup).forEach((v, i) => {
      if (newPlugin.type === v.type) {
        newPlugin.render = v.render;
        newPlugin.subPlugins = v.subPlugins;
        newPlugin.board = v.board;
        newPlugin.preview = v.preview;
        newPlugin.extension = v.extension;
        newPlugin.drawOptions = v.drawOptions;
        result = newPlugin;
      }
    });
    return result;
  };

  return (
    <div className="export-and-import-alert-wrap">
      <div className="top-area">
        <div className="tabs">
          <div
            className={CX({
              tab: true,
              active: isExportTabActive,
            })}
            onClick={() => {
              if (isExportTabActive) return;
              setIsExportTabActive(true);
            }}
          >
            导出
          </div>
          <div
            className={CX({
              tab: true,
              active: !isExportTabActive,
            })}
            onClick={() => {
              if (!isExportTabActive) return;
              setIsExportTabActive(false);
            }}
          >
            导入
          </div>
        </div>
        <CloseCircleOutlined
          onClick={() => {
            setShowExportAndImport(false);
          }}
          className="close-icon"
        />
      </div>
      <div className="main-content">
        {isExportTabActive && (
          <div className="export-content">
            <div ref={copyRef}>{createReleaseData()}</div>
            <div
              className="main-content-btn"
              onClick={() => {
                try {
                  navigator.clipboard.writeText(createReleaseData());
                  useMessage.success("已复制到剪切板");
                } catch (err) {
                  useMessage.error("复制失败");
                }
              }}
            >
              <CopyOutlined />
              一键复制
            </div>
          </div>
        )}
        {!isExportTabActive && (
          <div className="import-content">
            <TextArea
              style={{
                width: 793,
                height: 270,
                backgroundColor: "transparent",
              }}
              onChange={(v) => {
                importRef.current = v;
              }}
            />
            <div
              className="main-content-btn"
              onClick={() => {
                if (importRef.current === null) return;
                try {
                  // 表示正在导入数据，导入完成并获取完该模块的历史记录后再将其置为false
                  isInputInsert.value = true;
                  const data = JSON.parse(importRef.current);
                  const targetModule = findModuleByName(data.name);
                  if (targetModule && data) {
                    // 先将组件插入到页面中
                    insert(
                      data.plugins.map((item) => {
                        return mergeNewPluginsToOld(item);
                      })
                    );

                    // 由于data.plugins里的组件数量可能会小于等于该模块定义的组件数量(例如该模块应有4个组件，但我只用了两个组件)，因此需要对该data.plugins的组件进行补充
                    targetModule.plugins.forEach((plugin) => {
                      let hasCurrentPlugin = false;
                      data.plugins.forEach((importPlugin) => {
                        if (importPlugin.type === plugin.type) {
                          hasCurrentPlugin = true;
                        }
                      });
                      if (!hasCurrentPlugin) {
                        data.plugins.push(plugin);
                      }
                    });
                    setCurrentModule(Object.assign({}, data));
                    useMessage.success("导入成功");
                    setShowExportAndImport(false);
                  }
                } catch (err) {
                  useMessage.error("生成失败，请检查导入代码是否有误");
                }
              }}
            >
              <DownloadOutlined />
              一键生成
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
