import React, { useContext, useState, useEffect, useRef } from "react";
import { Space, Input, Checkbox, Alert } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { UserInfoContext, ModulesContext, GlobalStoreContext } from "@/context";
import { login as loginServer } from "@/server/userManager";
import "./index.less";

export default function Login() {
  const { useLogin, setUserInfo } = useContext(UserInfoContext);
  const { initModules } = useContext(ModulesContext);
  const { removeLimitTime } = useContext(GlobalStoreContext);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("用户名或密码错误");
  const [isChecked, setIsChecked] = useState(false);
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login, getSaveLoginInfo, logoff } = useLogin();

  const toMain = () => {
    navigate("/main", { replace: true });
  };

  const handleLogin = (acc, pas) => {
    if (acc !== "" && pas !== "") {
      // 登录
      loginServer(acc, pas).then(
        (data) => {
          setUserInfo(data);
          initModules();
          login(acc, pas, data.user_id, isChecked);
          setShowError(false);
          toMain();
        },
        () => {
          setShowError(true);
        }
      );
    } else {
      setShowError(true);
    }
  };

  useEffect(() => {
    const saveInfo = getSaveLoginInfo();
    const acc = saveInfo.account;
    const pas = saveInfo.password;
    const timestamp = saveInfo.timestamp;
    const isRemember = saveInfo.isRemember;
    // 超过一天取消自动登录
    if (Date.now() - timestamp > removeLimitTime) {
      if (acc && pas && isRemember === "true") {
        setIsChecked(true);
        setAccount(acc);
        setPassword(pas);
      }
      logoff();
    } else {
      if (acc && pas) {
        handleLogin(acc, pas);
      }
    }
  }, []);

  return (
    <div className="login-wrap flex-col justify-center">
      <div className="input-area">
        <div className="title flex-row items-center justify-center">
          <img src={require("@/assets/imgs/logo-colorful.png")} alt="" />
          <span>TGB科技</span>
        </div>

        <Space direction="vertical" size={28}>
          <div className="tab items-start flex-col">
            登录
            <div className="line" />
          </div>
          <Input
            value={account}
            style={{ borderRadius: 2 }}
            placeholder="请输入账号"
            prefix={<UserOutlined style={{ color: "#1890FF" }} />}
            onChange={(e) => {
              setAccount(e.target.value);
              // account.current = e.target.value
            }}
          />
          <Input.Password
            value={password}
            style={{ borderRadius: 2 }}
            placeholder="请输入密码"
            prefix={<LockOutlined style={{ color: "#1890FF" }} />}
            onChange={(e) => {
              setPassword(e.target.value);
              // password.current = e.target.value
            }}
          />
          <Space direction="vertical" size={22}>
            {showError && (
              <Alert
                message={errorMsg}
                type="error"
                showIcon={true}
                style={{
                  color: "#ff2e2e",
                }}
              />
            )}
            <Checkbox
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
                // isRememberPassword.current = e.target.checked
              }}
              className="check-box"
            >
              记住密码
            </Checkbox>
            <div
              className="submit"
              onClick={() => {
                handleLogin(account, password);
              }}
            >
              登录
            </div>
          </Space>
        </Space>
      </div>
      <img
        className="big-logo"
        src={require("@/assets/imgs/logo-colorful.png")}
        alt=""
      />
    </div>
  );
}
