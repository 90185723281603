import React, { useState, useEffect, useContext } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { AdvertProviderContext } from "@/context";
import "./index.less";

// 中间广告区域
export default function MiddleAdvArea() {
  const { bannerAdvertInfo } = useContext(AdvertProviderContext);

  const [isShow, setIsShow] = useState(false);
  const [swell, setSwell] = useState(false);
  useEffect(() => {
    setIsShow(!!bannerAdvertInfo.imgUrl);
  }, [bannerAdvertInfo]);
  return (
    isShow && (
      <div
        className="middle-adv-area z-index-1st"
        onMouseEnter={() => {
          setSwell(true);
        }}
        onMouseOut={() => {
          setSwell(false);
        }}
        onMouseLeave={() => {
          setSwell(false);
        }}
      >
        <img
          style={{
            transform: `translateX(-50%) scale(${swell ? 1.1 : 1})`,
            transition: "800ms",
            objectFit: "contain",
            position: "absolute",
            left: "50%",
            height: bannerAdvertInfo.height ? bannerAdvertInfo.height : 56,
          }}
          src={bannerAdvertInfo.imgUrl}
          alt=""
          onClick={() => {
            window.open(bannerAdvertInfo.jumpUrl);
          }}
        />
        <div
          className="float-icon"
          onClick={() => {
            setIsShow(false);
          }}
        >
          <CloseCircleOutlined />
        </div>
      </div>
    )
  );
}
