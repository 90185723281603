import React, { useContext, useState, useEffect } from "react";
import _ from "lodash";
import { Dropdown } from "antd";
import pluginsGroup from "@/plugins";
import {
  TipsContext,
  DrawPageStateContext,
  BaseConfigContext,
  InsertContext,
  UserInfoContext,
  GlobalStoreContext,
} from "@/context";
import NavigatorButton from "@/components/NavigatorButton";
import { saveHistory, getHistory } from "@/server/moduleHistory";
import { getYYMMDDHHMM } from "@/utils";
import { useNavigate } from "react-router-dom";
import { _template } from "@/modules";
import { Input } from "antd";
import { isIncludeSensitive } from "@/utils/sensitive";
import "./index.less";

// 顶部导航栏区域
export default function Navigator() {
  const { isLogin, userInfo, useLogin } = useContext(UserInfoContext);
  const { setPreviewData } = useContext(GlobalStoreContext);
  const { currentModule, setShowExportAndImport } =
    useContext(DrawPageStateContext);
  const { insertList, insert, init, isInputInsert } = useContext(InsertContext);
  const { maxSize } = useContext(BaseConfigContext);
  const { useMessage, useModal } = useContext(TipsContext);
  const [moduleHistoryList, setModuleHistoryList] = useState([]);
  const navigate = useNavigate();
  const { logoff } = useLogin();
  const { modal } = useModal();
  const createReleaseData = () => {
    let result = null;
    if (currentModule !== null && insertList.length > 0) {
      result = {
        baseConfig: maxSize,
        code: currentModule.code,
        name: currentModule.name,
        plugins: insertList.map((item) => {
          // 减少保存的字段长度
          return {
            name: item.name,
            type: item.type,
            props: item.props,
            rectOptions: item.rectOptions,
          };
        }),
        extraInfo: "",
      };
    }
    return result;
  };

  const mergeNewPluginsToOld = (newPlugin) => {
    let result = {};
    Object.values(pluginsGroup).forEach((v, i) => {
      if (newPlugin.type === v.type) {
        newPlugin.render = v.render;
        newPlugin.subPlugins = v.subPlugins;
        newPlugin.board = v.board;
        newPlugin.preview = v.preview;
        newPlugin.extension = v.extension;
        newPlugin.drawOptions = v.drawOptions;
        result = newPlugin;
      }
    });
    return result;
  };

  useEffect(() => {
    if (isLogin && currentModule !== null && userInfo.user_id) {
      getHistory({
        user_id: userInfo.user_id,
        module_id: currentModule.module_id,
      }).then((res) => {
        res = res.map((item) => {
          return {
            ...item,
            data: JSON.parse(item.data),
          };
        });
        if (res.length > 0) {
          setModuleHistoryList(res);

          // 如果是导入的数据，不将历史记录里的组件插入进来
          if (!isInputInsert.value) {
            const newInsertList = res[0].data.plugins.map((plugin) =>
              mergeNewPluginsToOld(plugin)
            );
            insert(newInsertList);
          }
        } else {
          setModuleHistoryList([]);
        }
        isInputInsert.value = false;
      });
    } else {
      setModuleHistoryList([]);
    }
  }, [currentModule, isLogin, userInfo]);

  return (
    <div className="navigator">
      <div className="left-area">
        <img src={require("@/assets/imgs/logo.png")} alt="" />
        <div className="name">TGB科技</div>
        <div className="box">
          <div>JD新版无线</div>
        </div>
      </div>

      <div className="right-area">
        <div className="feature-area">
          <NavigatorButton
            style={{ marginRight: 18 }}
            content="效果预览"
            onClick={() => {
              // 通过localStorage存储预览数据
              const releaseData = createReleaseData();

              if (releaseData) {
                if (_template.includes(releaseData.name)) {
                  useMessage.error(
                    "该模块暂不支持预览，请直接倒出配置到设计师中心预览"
                  );
                  return;
                }
                setPreviewData(releaseData);
                setTimeout(() => {
                  window.open("/preview.html", "_blank");
                }, 80);
              }
            }}
          />
          <NavigatorButton
            style={{ marginRight: 18 }}
            content="导入/导出"
            onClick={() => {
              setShowExportAndImport(true);
            }}
          />
          <Dropdown
            menu={{
              items: moduleHistoryList.map((historyItem, index) => {
                return {
                  key: index + "",
                  label: (
                    <div style={{ width: 350, overflow: "scroll" }}>
                      {getYYMMDDHHMM(historyItem.create_time)}
                      <span style={{ marginLeft: 40 }}>
                        {historyItem?.data?.extraInfo}
                      </span>
                    </div>
                  ),
                };
              }),
              onClick: (e) => {
                init();
                setTimeout(() => {
                  insert(
                    moduleHistoryList[Number(e.key)].data.plugins.map(
                      (plugin) => mergeNewPluginsToOld(plugin)
                    )
                  );
                }, 30);
              },
            }}
          >
            <div>
              <NavigatorButton
                style={{ marginRight: 18 }}
                content="模块记录"
                onClick={() => {}}
              />
            </div>
          </Dropdown>
          <NavigatorButton
            style={{ marginRight: 18 }}
            content="保存"
            onClick={() => {
              if (currentModule !== null) {
                const releaseData = createReleaseData();
                if (releaseData === null) {
                  useMessage.error("请先在画布上添加组件！");
                  return;
                }
                let saveInfo = "";
                modal(
                  {
                    title: "保存备注信息",
                    content: (
                      <div>
                        <Input
                          style={{ borderRadius: 2 }}
                          placeholder="请输入"
                          maxLength={12}
                          onChange={(e) => {
                            saveInfo = e.target.value;
                          }}
                        />
                      </div>
                    ),
                  },
                  () => {
                    if (isIncludeSensitive(saveInfo)) {
                      useMessage.error("名称中存在敏感词");
                    } else {
                      releaseData.extraInfo = saveInfo;
                      saveHistory({
                        module_id: currentModule.module_id,
                        user_id: userInfo.user_id,
                        data: releaseData,
                      }).then(
                        () => {
                          useMessage.success("保存成功!");
                        },
                        () => {
                          useMessage.error("保存失败!");
                        }
                      );
                    }
                  }
                );
              } else {
                useMessage.error("请先选择模块！");
              }
            }}
          />
        </div>
        <div className="line"></div>
        <div className="login-area">
          {isLogin === false ? (
            <NavigatorButton
              content="登录"
              type="login"
              onClick={() => {
                navigate("/", { replace: true });
              }}
            />
          ) : (
            <Dropdown
              menu={{
                items: [
                  {
                    key: "0",
                    label: "切换账号",
                  },
                  {
                    key: "1",
                    label: "退出登录",
                  },
                ],
                onClick: (e) => {
                  logoff();
                  navigate("/", { replace: true });
                  window.location.reload();
                },
              }}
            >
              <div className="flex-row items-center">
                <span className="name">{userInfo.user_name}</span>
              </div>
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
}
