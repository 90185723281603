import { useState, createContext } from "react";
// import initPlugins from '../plugins'
import _ from "lodash";

// export let pluginsVal = initPlugins

export const PluginContext = createContext({});

const PluginProvider = ({ children }) => {
  const [_plugins, _setPlugins] = useState([]);
  // pluginsVal = _plugins
  return (
    <PluginContext.Provider
      value={{
        plugins: _plugins,
        setPlugins: _setPlugins,
        getPlugin: (type) => {
          let result = null;
          _plugins.forEach((item) => {
            if (item.type === type) {
              result = item;
            }
          });
          return _.cloneDeep(result);
        },
      }}
    >
      {children}
    </PluginContext.Provider>
  );
};

export default PluginProvider;
